<template>
  <Modal
    :class="authenticationCompleted ? 'max-w-95' : 'max-w-141'"
    :id="`${authenticationModalType === 'reg' ? 'modal-registration' : ''}`"
    @close="closeModal"
  >
    <LazyAuthenticationAuth
      v-if="authenticationModalType === 'auth'"
      @success="onCloseAuthModal"
    />

    <LazyAuthenticationReg
      v-else-if="authenticationModalType === 'reg'"
      @success="closeModal"
    />

    <LazyAuthenticationForgotPassword
      v-else-if="authenticationModalType === 'forgotPassword'"
      @success="closeModal"
    />

    <!-- <LazyAuthenticationPartner
      v-else-if="authenticationModalType === 'partner'"
      @success="closeModal"
    >
    </LazyAuthenticationPartner> -->
  </Modal>
</template>

<script setup lang="ts">
import { authenticationCompletedKey, authenticationModalTypeKey } from './injectionKeys';
import type { AuthenticationModalType } from './type';
import { useCart } from '~/composables/cart/useCart';
import { useAuthPopup } from '~/composables/popups/useAuthPopup';

const authenticationCompleted = ref(false);
function updateAuthenticationCompleted () {
  authenticationCompleted.value = true;
}
const { getCartList } = useCart();
provide(authenticationCompletedKey, { is: authenticationCompleted, updateValue: updateAuthenticationCompleted });

const authenticationModalType = ref<AuthenticationModalType>('auth');
function authenticationUpdateModalType (type: AuthenticationModalType) {
  authenticationModalType.value = type;
}

provide(authenticationModalTypeKey, { type: authenticationModalType, updateValue: authenticationUpdateModalType });

const { closeModal, isModalShow } = useAuthPopup();

async function onCloseAuthModal () {
  await getCartList();
  closeModal();
}

watch(isModalShow, (newValue) => {
  if (!newValue) {
    authenticationModalType.value = 'auth';
    authenticationCompleted.value = false;
  }
});
</script>
