<template>
  <component
    :is="tag"
    :class="buttonClass"
  >
    <slot name="prefix">
      <span
        v-if="prefixIcon"
        :class="[iconClass, prefixIcon]"
      ></span>
    </slot>
    <slot />
    <slot name="suffix">
      <span
        v-if="suffixIconName"
        :class="[iconClass, suffixIconName]"
      ></span>
    </slot>
  </component>
</template>

<script lang="ts" setup>
type ButtonColor = 'green' | 'seryy' | 'white' | 'green-light' | 'blue'
type ButtonSize = 'sm' | 'md' | 'xs'
type ButtonVariant = 'outline' | 'solid'
type UIKeys = 'color' | 'text' | 'gap' | 'padding' | 'base' | 'rounded' | 'font'

const props = defineProps({
  tag: { type: [Object, String], default: 'button' },
  color: { type: String as PropType<ButtonColor>, default: 'green' },
  size: { type: String as PropType<ButtonSize>, default: 'md' },
  variant: { type: String as PropType<ButtonVariant>, default: 'solid' },
  class: { type: [String, Object, Array], default: undefined },
  prefixIcon: String,
  suffixIcon: String,
  loading: Boolean,
  loadingIcon: { type: String, default: '' },
  ui: { type: Object as PropType<Partial<Record<UIKeys, string>>> }
});

const slots = defineSlots<{
  default: any,
  prefix?: any,
  suffix?: any,
  loading?: any,
}>();

const suffixIconName = computed(() => {
  if (props.loading) { return props.loadingIcon; }
  return props.suffixIcon;
});

const ui: Record<UIKeys | 'icon', any> = {
  color: {
    'green-light': {
      solid: 'bg-firmennyy/10 text-firmennyy hover:bg-firmennyy hover:text-white',
      outline: 'bg-transparent ring-1.5 ring-inset ring-firmennyy/50 text-firmennyy hover:bg-firmennyy/10 hover:ring-firmennyy/25'
    },
    green: {
      solid: 'bg-firmennyy text-white hover:bg-firmennyy-bright',
      outline: 'bg-transparent ring-1.5 ring-inset ring-firmennyy/50 text-firmennyy hover:bg-firmennyy/20 hover:ring-transparent'
    },
    seryy: {
      solid: 'bg-plashka-seryy text-#718997 hover:bg-seryy-hover',
      outline: ''
    },
    white: {
      solid: 'bg-white text-firmennyy',
      outline: ''
    },
    blue: {
      solid: 'bg-[#0066FF] text-white',
      outline: ''
    }
  },
  text: {
    xs: 'text-3.75',
    sm: 'text-4',
    md: 'text-4 lg:text-4.5'
  },
  gap: {
    xs: 'gap-2',
    sm: 'gap-1.5',
    md: 'gap-1.5'
  },
  padding: {
    xs: 'py-2.75 px-7',
    sm: 'py-3.5 lg:py-3.75 px-7',
    md: 'py-3.5 lg:py-3.75 px-7'
  },
  base: 'flex items-center justify-center transition-box-shadow,background-color leading-0.8 -tracking-0.01em disabled:bg-border disabled:text-white',
  icon: {
    base: 'shrink-0',
    size: {
      xs: 'text-4',
      sm: 'text-4',
      md: 'text-4'
    }
  },
  rounded: 'rounded-3 lg:rounded-3.5',
  font: 'font-500'
};

const iconClass = computed(() => {
  return [ui.icon.base, ui.icon.size[props.size]];
});

const buttonClass = computed(() => {
  const variant = props?.ui?.color ?? ui.color?.[props.color]?.[props.variant];
  return [
    props?.ui?.color ?? ui.base,
    props?.ui?.font ?? ui.font,
    props?.ui?.rounded ?? ui.rounded,
    props?.ui?.text ?? ui.text[props.size],
    props?.ui?.gap ?? ui.gap[props.size],
    props?.ui?.padding ?? ui.padding[props.size],
    variant,
    props.class
  ];
});
</script>
