<template>
  <Modal
      :ui="{ root: { size: 'max-w-96', padding: 'px-3 py-1', addition: 'flex flex-row items-center gap-4', rounded: 'rounded-5'},
       close: { position: 'absolute top-36 left-1/2 -translate-x-1/2 -translate-y-full'}}"
      @close="emits('closeModal')"
  >
    <img
        width="64"
        height="64"
        src="/imgs/app-icon.svg"
        alt=""
    >
    <div class="flex flex-col py-1">
      <p
          class="text-[24px] font-700 leading-1.3"
      >
        SakhFood
      </p>
      <p class="text-[16px] text-#718997">
        Доставка еды<br>
        в Южно-Сахалинске
      </p>
    </div>

    <Button
        color="blue"
        class="rounded-8"
        @click="emits('redirectMobile')"
    >
      <p class="text-[18px]">
        Загрузить
      </p>
    </Button>
  </Modal>
</template>

<script setup lang="ts">
const emits = defineEmits(['closeModal', 'redirectMobile']);
</script>
