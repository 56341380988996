export const useLinkToMobileStorePopup = () => {
    const isModalShow = useState<boolean>('mobile-link-modal', () => false);

    const openModal = () => {
        isModalShow.value = true;
    };

    const closeModal = () => {
        isModalShow.value = false;
    };

    return { isModalShow, closeModal, openModal };
};
