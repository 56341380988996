import revive_payload_client_4sVQNw7RlN from "/home/igorFront/production/sakhfoodfront/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/igorFront/production/sakhfoodfront/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/igorFront/production/sakhfoodfront/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/home/igorFront/production/sakhfoodfront/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import debug_hAQZccAnsC from "/home/igorFront/production/sakhfoodfront/node_modules/nuxt/dist/app/plugins/debug.js";
import payload_client_yVLowv6hDl from "/home/igorFront/production/sakhfoodfront/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/igorFront/production/sakhfoodfront/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/home/igorFront/production/sakhfoodfront/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/igorFront/production/sakhfoodfront/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/igorFront/production/sakhfoodfront/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_ghbUAjaD3n from "/home/igorFront/production/sakhfoodfront/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import nuxt3_plugin_BdfRvWAAOY from "/home/igorFront/production/sakhfoodfront/node_modules/vue-yandex-maps/dist/plugins/nuxt3-plugin.js";
import plugin_tLmAP220nC from "/home/igorFront/production/sakhfoodfront/node_modules/@nuxt/image/dist/runtime/plugin.mjs";
import unocss_MzCDxu9LMj from "/home/igorFront/production/sakhfoodfront/.nuxt/unocss.mjs";
import chunk_reload_client_UciE0i6zes from "/home/igorFront/production/sakhfoodfront/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import metrica_5grsmfkU6g from "/home/igorFront/production/sakhfoodfront/plugins/metrica.js";
import customoOfetch_zBJTuv7VXR from "/home/igorFront/production/sakhfoodfront/plugins/customoOfetch.ts";
import initPlaginClient_client_Nsw0alNzzD from "/home/igorFront/production/sakhfoodfront/plugins/initPlaginClient.client.ts";
import priceFormat_Cb2yhyBrV2 from "/home/igorFront/production/sakhfoodfront/plugins/priceFormat.ts";
import recaptcha_W4US4xmBV6 from "/home/igorFront/production/sakhfoodfront/plugins/recaptcha.js";
import setCanonical_Ok2TJU0Ur6 from "/home/igorFront/production/sakhfoodfront/plugins/setCanonical.ts";
import toast_ysMjUcU7eJ from "/home/igorFront/production/sakhfoodfront/plugins/toast.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  debug_hAQZccAnsC,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_ghbUAjaD3n,
  nuxt3_plugin_BdfRvWAAOY,
  plugin_tLmAP220nC,
  unocss_MzCDxu9LMj,
  chunk_reload_client_UciE0i6zes,
  metrica_5grsmfkU6g,
  customoOfetch_zBJTuv7VXR,
  initPlaginClient_client_Nsw0alNzzD,
  priceFormat_Cb2yhyBrV2,
  recaptcha_W4US4xmBV6,
  setCanonical_Ok2TJU0Ur6,
  toast_ysMjUcU7eJ
]