<template>
  <footer class="grid-container bg-#1D3238 rounded-t-10 pt-6 lg:pt-12">
    <div>
      <div class="flex flex-col gap-y-6 gap-x-8 items-center lg:flex-row">
        <NuxtLink
          to="/"
          class="lg:mr-auto text-firmennyy hover:text-firmennyy-bright transition-colors"
        >
          <Logo class="w-full h-7.5 lg:h-10.75" />
        </NuxtLink>
        <a
          :href="`tel:${store.siteSettings?.phone}`"
          class="group/footerphone flex flex-col"
        >
          <span class="text-white font-700 text-7 lg:text-10 max-lg:text-center group-hover/footerphone:text-firmennyy-bright transition-colors">{{ formatPhoneNumber(store.siteSettings?.phone) }}</span>
          <span class="text-firmennyy font-500 text-4 lg:text-5 text-center lg:text-right group-hover/footerphone:text-firmennyy-bright transition-colors">Россия, бесплатно</span>
        </a>
        <div class="flex gap-4 text-white">
          <NuxtLink
            to="https://t.me/sakhfood"
            target="_blank"
            class="flex items-center justify-center size-10 lg:size-12 rounded-full bg-firmennyy hover:bg-firmennyy-bright transition-colors"
          >
            <span class="i-custom:tg text-6 lg:text-7 mr-0.5"></span>
          </NuxtLink>
        </div>
      </div>
      <div class="flex max-sm:flex-col lg:justify-between gap-8 lg:gap-16 mt-6 lg:mt-10 py-6 lg:py-9 border-y border-white/10 font-700 text-4.5 lg:text-5.5 text-white">
        <div class="flex gap-y-6 gap-x-6 lg:gap-x-14 items-center flex-row">
          <NuxtLink
              to="/contacts"
              class="hover:text-firmennyy-bright transition-colors flex items-center"
          >
            Контакты
          </NuxtLink>
          <button
              type="button"
              class="hover:text-firmennyy-bright transition-colors"
              @click="openPartnerPopup"
          >
            Стать партнёром
          </button>
          <NuxtLink
              to="/about-company"
              class="hover:text-firmennyy-bright transition-colors flex items-center"
          >
            О компании
          </NuxtLink>
          <!-- <NuxtLink
            to="/blog"
            class="hover:text-firmennyy-bright transition-colors"
          >
            Блог
          </NuxtLink> -->
        </div>
        <div class="flex flex-col gap-y-6 gap-x-4 items-center lg:flex-row">
          <p class="text-seryy text-3 lg:text-3.25 font-500">
            Понравился сервис? Оставьте отзыв:
          </p>
          <iframe
              src="https://yandex.ru/sprav/widget/rating-badge/83719183043?type=rating"
              width="150"
              height="50"
              frameborder="0"
          ></iframe>
        </div>
      </div>
      <div class="flex flex-col lg:flex-row lg:items-end gap-y-5 gap-x-10 justify-between pt-5 pb-6 lg:py-4.5 flex-wrap">
        <p class="text-seryy text-3 lg:text-3.25 font-500 max-lg:text-center pb-2">© SakhFood | 2025</p>
        <div class="flex justify-center gap-3 lg:gap-x-10 text-3 lg:text-3.25 underline text-seryy text-center pb-2">
          <NuxtLink
            to="/terms-of-use"
            class="hover:text-firmennyy transition-colors max-lg:max-w-40"
          >
            Пользовательское соглашение
          </NuxtLink>
          <NuxtLink
            to="/privacy"
            class="hover:text-firmennyy transition-colors max-lg:max-w-40"
          >
            Политика конфиденциальности
          </NuxtLink>
          <!-- <NuxtLink
            to="/info-for-partners"
            class="hover:text-firmennyy transition-colors max-lg:max-w-40"
          >
            Информация для партнеров
          </NuxtLink> -->
          <NuxtLink
            to="/details"
            class="hover:text-firmennyy transition-colors max-lg:max-w-40"
          >
            Реквизиты
          </NuxtLink>
        </div>
        <div class="flex flex-col lg:flex-row items-center gap-y-5 gap-x-7 max-lg:order-first">
          <p class="text-seryy text-3 lg:text-3.25 font-500">Принимаем к оплате</p>
          <div class="flex items-center gap-4">
            <img
              class="shrink-0 grow-0"
              width="63"
              height="19"
              src="/imgs/payments/mir.png"
              alt=""
            >
            <img
              class="shrink-0 grow-0"
              width="32"
              height="32"
              src="/imgs/payments/sbp.png"
              alt=""
            >
            <img
              class="shrink-0 grow-0"
              width="59"
              height="19"
              src="/imgs/payments/visa.png"
              alt=""
            >
            <img
              class="shrink-0 grow-0"
              width="39"
              height="24"
              src="/imgs/payments/mastercard.png"
              alt=""
            >
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { usePartnersPopup } from '~/composables/popups/usePartnersPopup';
import { siteSettingsStore } from '~/stores/site-settings-store';
import { formatPhoneNumber } from '~/assets/funcs/funcs';
const store = siteSettingsStore();

const { openPartnerPopup } = usePartnersPopup();
</script>
