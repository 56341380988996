<template>
  <TransitionModal>
    <LazyRestaurantProductModal
      v-if="isProductPopupOpen"
      @closeModal="closeProductPopup"
    />
  </TransitionModal>
  <TransitionModal>
    <LazyModalAddAddress
      v-if="isAddressPopupOpen"
      @closeModal="closeAddressPopup"
    />
  </TransitionModal>
  <TransitionModal>
    <LazyReviewsRestaurantModal
      v-if="isRestaurantReviewsPopupOpen"
      @closeModal="closeRestaurantReviewsPopup"
    />
  </TransitionModal>
  <TransitionModal>
    <LazyAuthenticationPartner
      v-if="isPartnerPopupOpen"
      @closeModal="closePartnerPopup"
    />
  </TransitionModal>
  <TransitionModal>
    <ModalLinkToMobileStore
      v-if="isModalShow"
      @redirectMobile="redirectMobile"
      @closeModal="closeModal"
    />
  </TransitionModal>
</template>

<script setup lang="ts">
import { useProductPopup } from '~/composables/popups/useProductPopup';
import { useAddingAddressPopup } from '~/composables/popups/useAddingAddressPopup';
import { useRestaurantReviewsPopup } from '~/composables/popups/useRestaurantReviewsPopup';
import { usePartnersPopup } from '~/composables/popups/usePartnersPopup';
import { useLinkToMobileStorePopup } from "~/composables/popups/useLinkToMobileStorePopup";

const { isPartnerPopupOpen, closePartnerPopup } = usePartnersPopup();
const { isProductPopupOpen, closePopup: closeProductPopup } = useProductPopup();
const { isAddressPopupOpen, closePopup: closeAddressPopup } = useAddingAddressPopup();
const { isRestaurantReviewsPopupOpen, closePopup: closeRestaurantReviewsPopup } = useRestaurantReviewsPopup();
const { isModalShow, openModal, closeModal } = useLinkToMobileStorePopup();

const { isAndroid, isIos } = useDevice()

const redirectMobile = () => {

  if (isIos) {
    window.open('https://apps.apple.com/ru/app/sakhfood/id6505093916', '_blank');
  } else if (isAndroid || !isIos) {
    window.open('https://play.google.com/store/apps/details?id=com.sakhfood', '_blank');
  }
};
const checkIsMobile = () => isIos || isAndroid ? openModal() : null;

onBeforeMount(() => checkIsMobile());

</script>
